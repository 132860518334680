@import url("../../styles/constants.css");

.image-primary {
  opacity: 1;
  width: 100%;
  height: auto;
}

.avatar {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 1px solid var(--white);
}

.avatar-background {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: var(--white);
  display: inline-block;
  margin: 15px;
}

.dropdown:focus-within .dropdown-menu {
  opacity:1;
  transform: translate(0) scale(1);
  visibility: visible;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */


#snackbar {
  visibility: auto; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  height: auto;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  /*display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: rgb(229 231 255);
}

::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #B2BCAA;
  cursor: pointer;
  border-radius: 50%;
}

::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #B2BCAA;
  cursor: pointer;
  border-radius: 50%;
}

#bodyNA {
  &::-webkit-scrollbar {
    width: 16px;
    height: 10px;
    border: 5px solid white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    background-clip: padding-box;
    /* border: 0.05em solid #eeeeee; */
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #888888;
  }
  
  /* Buttons */
  /* &::-webkit-scrollbar-button:single-button {
    background-color:  #f5f5f5;
    display: block;
    border-style: solid;
    height: 13px;
    width: 16px;
  } */
  /* Left arrow */
  /* &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #555555 transparent;
    position: relative;
  } */

  /* Right arrow */
  /* &::-webkit-scrollbar-button:single-button:horizontal:increment {
    border-width: 8px 8px 0 8px;
    border-color: #555555 transparent transparent transparent;
  } */

}

.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888888 #f1f1f1; /* For Firefox */

  /* For WebKit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* &::-webkit-scrollbar-button {
    background-color: #888888;
    width: 10px;
  } */
}

.rtl-text {
  direction: rtl;
}

[type='text']:focus {
  box-shadow: none;
}

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .typing-animation {
    /* display: inline-block; */
    overflow: hidden;
    white-space: nowrap;
    animation: typing 2s steps(40);
  }

  @layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    h4 {
      @apply text-base;
    }
    h5 {
      @apply text-sm;
    }
    h6 {
      @apply text-xs;
    }
    normal {
      @apply text-base;
    }
  }

.bg {
  /* The image used */
  /* background-image: url("./assets/authorizationBg.jpg"); */
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Firefox */
* {
  scrollbar-width: thin;
  /*scrollbar-color: var(--primary) var(--white);*/
}

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--white);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 14px;
} */

/* *::-webkit-scrollbar-button {
  background-color: #ccc;
  border: 1px solid #aaa;
} */

.line-cut-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.rdrInputRanges {
  display: none;
}

.text-xxs {
  font-size: 10px;
  line-height: 12px;
}

.popup-open {
  overflow: hidden; /* Disable scrolling */
  pointer-events: none; /* Disable clicking on elements behind */
}

@keyframes marqueeAnimation {
  from {
      transform: translateX(200%);
  }
  to {
      transform: translateX(-200%);
  }
}

.marquee-container {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.marquee-content {
  display: flex;
  white-space: nowrap;
  animation: marqueeAnimation 30s linear infinite;
  animation-fill-mode: forwards; 
}

.marquee-content span {
  margin-right: 20px;
}

.bg-standard {
  /* background-image: url("./assets/svg/background.svg"); */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.Toastify__toast-body {
  align-items: start;
}

/** Used to position the icon **/
.Toastify__toast-icon {
  padding-top: 5px;
}
.logo {
  max-width: 100%;
  margin: auto;
}

* { -webkit-tap-highlight-color: rgba(0,0,0,0); }

@media only screen and (min-width: 768px) {
  .drawer {
    width: 20% !important;
  }
}

@media only screen and (max-width: 768px) {
  .drawer {
    width: 70% !important;
  }
}

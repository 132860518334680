@import url("constants.css");
/*------------------------------------------------------------------
[Main Stylesheet]
Project:    AgileAP
Version:    1.0.000
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    1. Default tags
    2. Common styles
    3. Text styles
*/

/* #region Default tags*/
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  font-family: "Poppins" !important; 
  background-color: #F9F9F9;
}

a {
  font-size: 16px !important;
  cursor: pointer;
  color: var(--primary);
  font-family: var(--font_family_hyperlink);
}

a:hover {
  color: var(--secondary);
  text-decoration: none;
}

/* p {
  font-size: var(--font_primary_size) !important;
  line-height: var(--paragraph_line_space) !important;
  color: var(--font_primary);
  font-family: var(--font_regular) !important;
}

h1 {
  font-size: var(--font_header_size) !important;
  line-height: var(--header_line_space) !important;
  color: var(--font_primary);
  font-family: var(--font_bold) !important;
}

h5 {
  font-size: var(--font_menu_size) !important;
  line-height: var(--paragraph_line_space) !important;
  color: var(--cancel);
  font-family: var(--font_bold) !important;
} */
/* #endregion */

/* #region Common styles*/
.clickable {
  opacity: 1;
  cursor: pointer;
  transition: 0.5s ease;
}

.clickable:hover {
  opacity: 0.6;
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.background {
  background-image: url("../assets/background.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-colour {
  background-color: var(--background_secondary);
  height: 100vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
/* #endregion */

/* #region Text styles*/
.font-right {
  text-align: right;
}

.font-center {
  text-align: center;
}
/* #endregion */

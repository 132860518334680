/* Custom alignment and styling for rc-time-picker input */
.rc-time-picker-input {
    border: none; /* Removes the border */
    outline: none; /* Removes the focus outline */
    /*text-align: center; !* Centers the text inside the input *!*/
    font-size: 11px; /* Adjust font size */
    margin-top: 5px;
}

/* Optional: Customize the dropdown panel */
.rc-time-picker-panel {
    border-radius: 8px; /* Add rounded corners */
    /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); !* Add shadow *!*/
}
.rc-time-picker-clear {
    display: none; /* Hides the cross icon */
}
/* Optional: Style the selected time */
li.rc-time-picker-panel-select-option-selected {
    background-color: #e45e37; /* Custom background for selected option */
    color: white; /* Custom text color */
}

.rc-time-picker-panel-select li:hover {
    background-color: #dca26d; /* Custom background for selected option */
    color: white;
}
.scrollbox {
	/* overflow: auto; */
	/* max-width: 400px;
	max-height: 200px; */
	/* margin: 50px auto; */
  background-image: 
	
    /* Shadows */ 
    linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
  
/* Shadow covers */ 
    linear-gradient(to right, rgba(120, 120, 120, 0.25), rgba(255,255,255,0)),
    linear-gradient(to left, rgba(120, 120, 120, 0.25), rgba(255,255,255,0));   

  background-position: left center, right center, left center, right center;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 10px 100%, 10px 100%, 10px 100%, 10px 100%;
	
	/* Opera doesn't support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
  border-radius: 8px;
}

.pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

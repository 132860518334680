@import url("../../styles/constants.css");

.backdrop.show {
  opacity: 1;
  pointer-events: visible;
}

.modal-content {
  transform: translateY(100vh);
  transition: all 0.3s ease-in-out;
}

.backdrop.show .modal-content {
  transform: translateY(0);
}

@media only screen and (max-width: 768px) {
  .backdrop {
    align-items: flex-end;
  }

  .modal-content {
    width: 100vw;
  }

  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@import url("../../styles/constants.css");

.storybook-button {
  font-family: var(--font_regular);
  font-size: var(--font_primary_size);
  border: 0;
  border-radius: 0.5em;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  width: 100%;
  color: var(--white);
  opacity: 1;
  cursor: pointer;
  transition: 0.5s ease;
}

.storybook-button--primary {
  background-color: var(--primary);
}

.storybook-button--secondary {
  background-color: var(--secondary);
}

.storybook-button--cancel {
  background-color: var(--cancel);
}

.storybook-button:hover {
  opacity: 0.6;
}

.storybook-button--disabled {
  background-color: var(--warning);
  opacity: 0.2;
  cursor: default;
}

.storybook-button--disabled:hover {
  opacity: 0.2;
}

.storybook-button-icon {
  padding: 8px;
  /* width: auto; */
}
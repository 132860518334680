@import url("../../../styles/constants.css");

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {

  font-size: var(--font_primary_size);
  box-sizing: content-box;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 5px;
  padding: 8.5px 14px;
}
.number-input-container {

  box-sizing: content-box;
  border: 1px solid var(--cancel);
  border-radius: 5px;
}

.number-input-container:hover {
  border: 1px solid var(--black);
}
